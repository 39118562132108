import Ustacky from '../../assets/Ecommerce.png';
import Dictionary from '../../assets/Dictionary.png';
import LandingPage from '../../assets/landingPage.png';
import Signup from '../../assets/signUp2.png';
import HNG2 from '../../assets/Hng2.png';
import Blog from '../../assets/myBlog.png';
import Shop from '../../assets/RShop.png';
import Banking from '../../assets/Bank.png';
import JobApp from '../../assets/jobapp.png';
import ChatApp from '../../assets/Chats.png';
import MeanApp from '../../assets/MeanApp.png';
import MernApp from '../../assets/Mern.png';
import Meal from '../../assets/Meal.png';
import Dashboard from '../../assets/dashboard.png';
import Thumbnail from '../../assets/portfolio.png';
import Colorgen from '../../assets/colorgen.png';

export const projects = [
	{
		link: 'https://hotel-app-blush-beta.vercel.app',
		Img: MernApp,
		title: 'MERN: Hotel Reservation App',
		description:
			'Technologies: Next Js, Typescript,  Mongoose, MongoDB and Tailwind'
	},
	{
		link: 'https://bank-app.wealthtech.website',
		Img: MeanApp,
		title: 'MEAN Stack Wallet Application',
		description:
			'Technologies: Angular, Ngrx, Typescript, Node, Express, Mongoose, MongoDB and Tailwind'
	},
	{
		link: 'https://bankapp-react.netlify.app',
		Img: Banking,
		title: 'Banking App',
		description:
			'Technologies: React, Redux, Firebase, Routing and Code spliting, Real Time Transactions & Tailwind  CSS'
	},
	{
		link: 'https://react-type-dashboard.netlify.app',
		Img: Dashboard,
		title: 'React Admin Dashboard',
		description: 'Technologies: React, Typescript, Tailwind, Apex-Charts'
	},
	{
		link: 'https://wealthblog.netlify.app',
		Img: Blog,
		title: 'A React Blog',
		description:
			'Technologies: React Js, Tailwind CSS, Redux, restApi, Authentication, Protected Routes, replies & like features etc'
	},
	{
		link: 'https://recipemeals.netlify.app',
		Img: Meal,
		title: 'A React Recipe Site',
		description:
			'Technologies: React Js, Tailwind, Axios for API request(s), Promises Combinators, Event delegation, router etc'
	},
	{
		link: 'https://tedbreejobapp.netlify.app',
		Img: JobApp,
		title: 'Job App',
		description:
			'Technologies: React Js, Context, modals, Tailwind  CSS, Routing and Code spliting'
	},
	{
		link: 'https://wealthchats.netlify.app',
		Img: ChatApp,
		title: 'Chat App',
		description:
			'Technologies: React Js, Firebase Cloud Storage, Firebase Auth, Firestore  & Tailwind  CSS'
	},
	{
		link: 'https://ustackyecommerce.netlify.app',
		Img: Ustacky,
		title: 'An E-commerce Website',
		description:
			'Technologies: HTML, CSS & Javacript; Functional programing, Integrated with Paystack for Payment'
	},
	{
		link: 'https://redux-ecommerceshop.netlify.app',
		Img: Shop,
		title: 'A React E-commerce Website',
		description:
			'Technologies: Reactjs, Redux, and Local Storage to Persist Data'
	},
	{
		link: 'https://react-dict.netlify.app',
		Img: Dictionary,
		title: 'A Dictionary App',
		description:
			'Built with Audio Support and Dark-Mode Toggle; using ReactJs, axios, Google Api, Tailwind CSS'
	},
	{
		link: 'https://hngprojectsite.netlify.app',
		Img: HNG2,
		title: 'A Complete Website',
		description:
			'Implemented from a Figma Design using HTML, CSS & Javacript(Fully Responsive)'
	},
	{
		link: 'https://wealthportfolio.netlify.app',
		Img: Thumbnail,
		title: 'A Portfolio Website',
		description:
			'Built with Reactjs, Tailwind CSS- Responsive on all devices'
	},
	{
		link: 'https://wealthreact-projects.netlify.app',
		Img: Colorgen,
		title: 'React Workflow Logics',
		description:
			'16 react projects using React hooks, useReducer, useContext, useParams,CRUD, API Integrations etc..'
	},
	{
		link: 'https://sitetests.netlify.app',
		Img: LandingPage,
		title: 'Landing Page',
		description:
			'Implemented Figma Design- A Responsive Landing Page split into components'
	},
	{
		link: 'https://company-task.netlify.app/question3',
		Img: Signup,
		title: 'An Advanced Signup Form',
		description: 'Implemented Figma Design- A Responsive Signup Form'
	}
];

export const jobs = [
	{
		startDate: '05/2024',
		endDate: 'Till Date',
		company: 'Vendease Africa',
		position: 'Lead Frontend Developer',
		stack: 'Technologies used: Angular 16, Typescript, Tailwind, SCSS,	GIT, Docker, Sonar Lint',
		milestone: `Promoted to lead team in three months, shortly after confirmation and we were able to deploy project's release 1.0 to production within project
						timelines.`,
		duties: [
			`Implemented Layouts, responsive pixel perfect figma designs,
							fit for all device screen breakpoints (mobile,
							tablets, and desktop).`,
			`Validated User form-inputs and integrated backend APIs with good
							error handling.`,
			` Implementing refined product features according to PRD`,
							`Enforcing best practices for optimizing our web application through
 code reviews, and recommended
							better ways to implement features, without trading
							off performance and security.`,
			`Drive conversations during backlog refinement/groomimg sessions`,
			`Reached story point completion rate consistently of
							100% except for tickets with external blockers.`,
			`	Offer technical assistance through coding session to
							team members to close out sprint tickets.`,
			`Organized Knowledge sharing sessions for custom  implementations to break-down feature implementation and address concerns of fellow engineers`,
			`Raised Deployment Request (DR) accross different
							environments (staging and production) after sprints.`,
			`Training interns on fundamentals and best practices
							of frontend technologies; and assisting them in
							reviewing & debugging their solutions.`
		]
	},
	{
		startDate: '02/2022',
		endDate: '06/2023',
		company: '	Remita Payment Service Limited',
		position: 'Frontend Developer',
		stack: `Technologies used: Angular, React, Jquery, Javascript, Typescript, JSP, NGRX, JWT, Bootstrap-4`,
		milestone: `Developed an internal solution (Admin Console) with
						RBAC (Role Based Access Control) that drastically reduced
						the processing time of client requests, even over the
						weekend by 80%. It modifies records on the database
						using dynamic forms and stored procedures that executes
						SQL queries`,
		duties: [
			`Implemented Layouts, responsive pixel perfect figma designs,
							fit for all device screen breakpoints (mobile,
							tablets, and desktop).`,
			`	Integrated APIs on our frontend Angular and
							javascript applications using JSON Web Tokens (JWT)
							and cookies.`,

			`Built more reusable components (modals, tables,
							clipboard, inputs, password-validators) across our
							angular frontend for easy debugging and testing`,
			`Maintained two legacy projects (payment gateway and
							payment and collection web-application) by
							debugging, refactoring and implementing new
							features.`,

			`	Implemented i18 language translation feature and
							geospatial/geo-location map representation on our
							angular frontend.`,

			`Migrated UI elements into newly built reusable
							components, and migrated projects into mono-repo
							architecture using Nx.`,

			`Implemented web accessibility principles with
							accordance to WCAG 2.1 across web applications.`,
			`Delivered three practical live coding demo for
							frontend team, and management to update them on
							projects status, and newer technologies.`,

			`Training interns on fundamentals and best practices
							of frontend technologies; and assisting them in
							reviewing & debugging their solutions.`
		]
	},
	{
		startDate: '12/2021',
		endDate: '01/2022',
		company: 'Zuri Chat',
		position: 'Frontend Developer',
		stack: `Technologies used: React, Angular, Typescript,
						Javascript, Redux, JWT, Bootstrap, Tailwind`,
		milestone: ``,
		duties: [
			`Implemented Layouts, responsive pixel perfect figma designs,
							fit for all device screen breakpoints (mobile,
							tablets, and desktop).`,
			`Implemented app-wide state management using redux
							with local storage sync and contextAPI.`,

			`Integrated APIs on our frontend React application
							using JSON Web Tokens(JWT).`,
			`Collaboration with UI/UX designers, backend, and
							other frontend developers on group tasks using
							gitHub, zoom, google meet to deliberate on tasks
							remotely.`
		]
	},
	{
		startDate: '08/2020',
		endDate: '02/2021',
		company: 'Auntie Network ',
		position: 'Contract Frontend Developer',
		stack: `Technologies used: React, HTML, CSS, Javascript, Redux,
						Bootstrap, Jquery`,
		milestone: `Resolved bugs which were deployment show stoppers and got a financial tip shortly after `,
		duties: [
			`Completed frontend tasks; implementing pixel perfect
							designs, consuming rest APIs, improving user
							experience on all devices through mobile
							responsiveness.`,
			`Worked on the chat component and integrated APIs
							using web-socket for real-time messaging.`,

			`Integrated APIs on our frontend React application
							using JSON Web Tokens(JWT).`,
			`Collaboration with UI/UX designers, backend, and
							other frontend developers on group tasks using
							gitHub, zoom, google meet to deliberate on tasks
							remotely.`
		]
	}
];
