import React from 'react';
import Img from '../assets/wealthy.jpg';
import { FaDownload } from 'react-icons/fa';
import Resume from './Wealth-Resume.pdf';
import Fade from 'react-reveal/Fade';

const About = () => {
	return (
		<div
			className='bg-wheat flex mt-10 lg:mt-6 lg:min-h-screen justify-center items-center'
			id='About'>
			<Fade bottom opposite>
				<div className='flex flex-col md:flex-row w-10/12 justify-between space-y-5 lg:space-y-0 items-center'>
					<div>
						<img
							className='w-40 h-40 object-cover  rounded-full  shadow-2xl'
							src={Img}
							alt='wealth'
						/>
					</div>

					<div className='space-y-5'>
						<p className='mx-auto max-w-2xl lg:mx-0 md:max-w-lg lg:max-w-2xl  text-md text-justify '>
							<span>
								{' '}
								An experienced software engineer with +4 years
								of experience in frontend development (Angular &
								React), and a year in backend development(Node,
								Express). With a keen eyes for details, Wealth
								excels in implementing pixel-perfect UI designs
								while ensuring optimal user experience through
								the use of readable, reusable, and scalable
								code. Wealth's expertise lies in frontend
								frameworks such as React and Angular, employing
								functional programming and object-oriented
								programming respectively.
							</span>
							<br />
							<br />
							<span>
								{' '}
								Proficient in various aspects of web
								development, Wealth understands DOM
								manipulation, event handling, unit testing,
								end-to-end testing, code versioning, closures,
								pure functions, asynchronous programming,
								backend development, testing, performance,  API integration,
								error handling, user experience (UX), web content
								accessibility and many more. This knowledge
								enables wealth build reusable components,
								maintainable and accessible web applications that scales.
							</span>
							<br />
							<br />
							<span>
								{' '}
								Worked as a software engineer at Remita Payment
								Service Limited, a subsidiary of systemspecs
								group, Wealth has played a crucial role in the
								development of the internal solution called
								Admin console, with over 80% slash in processing
								time of business request and has contributed to
								the creation of RemitaAgent, an enterprise
								fintech application, from the ground up using
								Angular 14.
								<br />
								<br /> Currently wealth works with vendease
								africa as an frontend lead (Angular), He drives
								conversations during technical refinement,
								reviews code implemention and drives deployment
								to different environments and contributes to
								projects, particularly super app with amazing
								feat; (e-commerce, Menu Management, Device
								Management, Shift Management, Team Management
								for Restaurant Management System) using angular
								16 (standalone architecture). Additionally,
								Wealth's commitment to continuous learning,
								paired with a strong work ethic, has earned
								accolades during bi-annual appraisal cycles.
							</span>
							<br />
							<br />
							With a comprehensive skill set encompassing;
							<br />
							<span className='font-bold'>
								{' '}
								JavaScript, TypeScript, Angular, React, Next,
								Ngrx, Redux, Jasmine, Cypress, CSS, SCSS, JWT,
								jQuery, HTML, Tailwind, Bootstrap, Docker, REST
								API, Node, Express, Mongoose, MongoDB, and Git.
							</span>
							<span>
								{' '}
								Wealth is well-equipped to tackle a wide range
								of frontend development challenges. Passionate
								about innovative implementations and captivating
								designs, Wealth consistently strives to deliver
								exceptional results.
							</span>
						</p>

						<a className='block' href={Resume}>
							<button
								className='py-4 mx-auto rounded-br-3xl lg:mx-0 px-4 space-x-4 flex rounded-full items-center bg-gray-700  transition duration-1000 hover:bg-gray-500'
								style={{ color: 'wheat' }}>
								<span className='mx-2'>
									<FaDownload />
								</span>
								<span>Download Resume</span>
							</button>
						</a>
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default About;
