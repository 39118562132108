import React from 'react';
import { FaAngleDoubleDown } from 'react-icons/fa';
import Cards from './Cards';
import Jobs from './Jobs';
import { projects } from './data/data';

const Projects = () => {
	return (
		<div className='w-10/12  lg:mt-0  mt-10 mx-auto'>
			<Jobs />
			<section id='Projects'>
				<h1 className='text-xl flex items-center mt-10 justify-center md:text-2xl lg:text-3xl text-gray-600 font-bold '>
					<span>Personal Projects</span>
					<span className='mx-2 animate-ping text-sm'>
						<FaAngleDoubleDown />
					</span>
				</h1>

				<div className='grid cursor-pointer gap-y-6 gap-x-4 mt-10    grid-col-1 sm:grid-cols-2  justify-items-center  md:grid-cols-2 justify-center lg:grid-cols-3'>
					{projects.map(({ title, link, Img, description }) => (
						<Cards
							key={link}
							projectLink={link}
							alt={title}
							imgLink={Img}
							projectTitle={title}
							projectDesc={description}
						/>
					))}
				</div>
			</section>
		</div>
	);
};

export default Projects;
